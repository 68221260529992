<template>
  <div class="privacySettingsDetail" v-html="result.protocolContent">
    {{result.protocolContent}}
  </div>
</template>
<script>
import { procotolDetail} from "../services/api";
export default {
  data() {
    return {
      result:{ },
    };
  },
  created: function() {
    var protocolNo=this.$route.query.protocolNo;
    this.procotolDetail(protocolNo);
  },
  methods: {
    async procotolDetail(protocolNo) {
      const datas = await procotolDetail({protocolNo:protocolNo});
      if (datas.code == 1) {
         this.result=datas.data.result;
      }
    },
    
  }
};
</script>
<style lang="scss" scoped>
.privacySettingsDetail{
  background: #fff;
  margin:1rem;
  padding: 1rem;
  color:#999;
  line-height: 1.5rem;
  p{font-size: 0.8rem;line-height:2rem;}
}
</style>
